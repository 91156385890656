'use strict';

module.exports = function () {
    // general trigger for tooltips (promotions, punti natura...)
    $('.products, body').on('click', '.info-icon', function () {
        var $tooltip = $(this).find('.tooltip');

        if ($tooltip.hasClass('d-none')) {
            $(this).find('.tooltip').removeClass('d-none');
        } else {
            $(this).find('.tooltip').addClass('d-none');
        }
    });

    // trigger for promotion tooltips in minicart
    $('body').on('minicartmodal:toggled', function () {
        var $infoIcon = $('.minicart-modal').find('.tooltip-trigger');

        $($infoIcon).off('click');

        $($infoIcon).on('click', function () {
            var $tooltip = $(this).find('.tooltip');

            if ($tooltip.hasClass('d-none')) {
                $(this).find('.tooltip').removeClass('d-none');
            } else {
                $(this).find('.tooltip').addClass('d-none');
            }
        });
    });

    // trigger for netreview tooltips in pdp
    $('.netreview-tooltip .info-icon').on('mouseenter', function () {
        var $nrtooltip = $('.netreview-tooltip .tooltip');
        if ($nrtooltip.hasClass('d-none')) {
            $nrtooltip.removeClass('d-none');
        }
    });
};
